import { render, staticRenderFns } from "./SubList.vue?vue&type=template&id=50701535&scoped=true&"
import script from "./SubList.vue?vue&type=script&lang=js&"
export * from "./SubList.vue?vue&type=script&lang=js&"
import style0 from "./SubList.vue?vue&type=style&index=0&id=50701535&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50701535",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAlert,VBtn,VFileInput,VIcon,VPagination,VProgressCircular,VSimpleTable})
