<template>
  <div class="SourceList">
    <Breadcrumbs :curData="curApplicationName" class="main-top">
      <template slot="action-buttons">
        <v-btn
          class="ml-3"
          depressed
          color="primary"
          style="position:relative;"
        >
          <v-file-input
            @change="uploadFile"
            accept=".xls, .xlsx"
            style="position:absolute;z-index:999;width: 200px;height:66px;flex: none; background: none;opacity:0"
          ></v-file-input>
          <v-icon class="pr-1">mdi-database-import-outline</v-icon>
          {{ $t("application.Import SubApplication") }}
        </v-btn>
        <v-btn class="ml-3" depressed color="primary" @click="exportUser">
          <v-icon class="pr-1">mdi-database-export-outline</v-icon>
          {{ $t("application.Export SubApplication") }}
        </v-btn>
        <v-btn class="ml-3" depressed color="primary" @click="createSubAccount">
          {{ $t("application.Create SubApplication") }}
        </v-btn>
      </template>
    </Breadcrumbs>
    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      <span>子账户</span><br />
      子账户指的是在指定应用系统中，用户会以什么身份进行访问。主账户指的是 IAM
      中的账户。在进行单点登录时，IAM
      会向应用系统传递对应的子账户，该子账户需要在应用系统中存在且可识别。
      账户关联方式：在应用创建时，如果选择了账户映射，即默认主账户和子账户完全一致，无需配置。如果选择了账户关联，则需要在这里进行手动的子账户创建和主子账户关联。
    </v-alert>

    <v-simple-table class="table-wrapper" style="position:relative;width:100%">
      <template v-slot:default>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          style="position:absolute;top:20%;left:50%;margin-left:-16px;"
        ></v-progress-circular>
        <thead>
          <tr>
            <th class="text-left pl-4">
              ID
            </th>
            <th class="text-left">
              {{ $t("Account ID") }}
            </th>
            <th class="text-left">
              {{ $t("Login Name") }}
            </th>
            <th class="text-left">
              {{ $t("SubApplication Name") }}
            </th>
            <th class="text-left">
              {{ $t("SubApplication Description") }}
            </th>
            <th class="text-center">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="sources.length > 0">
          <tr v-for="item in sources" :key="item.id" class="text-left">
            <td class="pl-4">{{ item.id }}</td>
            <td>
              <v-btn depressed link :to="`/account/view/${item.account.id}`">{{
                item.account.external_id
              }}</v-btn>
            </td>
            <td>
              {{ item.account.login_name }}
              <span v-if="item.account.fullname">{{
                `(${item.account.fullname})`
              }}</span>
            </td>
            <td>{{ item.claims.username }}</td>
            <td
              :style="{
                color: item.description ? '' : '#9195a3'
              }"
            >
              {{ item.description || "无" }}
            </td>
            <td class="text-center">
              <v-btn icon @click.stop="edit(item)">
                <v-icon>
                  mdi-square-edit-outline
                </v-icon>
              </v-btn>
              <v-btn icon elevation="0" @click.stop="show_info(item)" v-if="0">
                <v-icon>
                  mdi-text-box-search-outline
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                color="red"
                @click.stop="deleteSource(item)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <Component
      :is="sheet_editor"
      :subType="type"
      :subId="curId"
      :curSubData="curSubData"
      :curApplicationName="curApplicationName"
      :is_readonlySub="is_readonly"
      :curProviderType="curProviderType"
    />
    <ConfirmBox ref="confirm" />
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";
import CreateSubApplication from "@/views/application/editor/CreateSubApplication";
import EditSubApplication from "@/views/application/editor/EditSubApplication";

const EDITORS = {
  CreateSubApplication: CreateSubApplication,
  EditSubApplication: EditSubApplication
};

export default {
  name: "SubApplicationList",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      sheet_editor: null,
      current_id: null,
      show_confirm: null,
      sources: [],
      curSubData: {},
      is_readonly: null,
      curProviderType: null,
      type: null,
      curId: "",
      curApplicationName: "",
      loading: false
    };
  },
  created() {
    this.getCurIdData(this.$route.params.id);
    this.refreshList();
    bus.$off("refreshSub").$on("refreshSub", () => {
      this.onPageChange(this.curPage, 10);
    });
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  methods: {
    createSubAccount() {
      this.type = "create";
      this.is_readonly = null;
      this.sheet_editor = EDITORS["CreateSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    edit(item) {
      this.curId = item.id;
      this.current_id = item.id;
      this.curSubData = item;
      this.is_readonly = null;
      this.type = "edit";
      this.sheet_editor = EDITORS["EditSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    onPageChange(curPage, limit) {
      this.refreshList(curPage, limit);
    },
    show_info(item) {
      this.curId = item.id;
      this.curSubData = item;
      this.current_id = item.id;
      this.is_readonly = true;
      this.type = "view";
      this.sheet_editor = EDITORS["EditSubApplication"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    },
    deleteSource({ id, claims: { username } }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除子账户？ #${id} ${username}`,
          callback: () => {
            return this.$http
              .post(
                `api/application/${this.$route.params.id}/sub_account/${id}/archive`
              )
              .delegateTo(api_request)
              .then(() => {
                this.curPage =
                  this.curPage > Math.ceil((this.totalPage - 1) / 10)
                    ? this.curPage - 1
                    : this.curPage;
                if (this.totalPage - 1 > 0) {
                  this.onPageChange(this.curPage, 10);
                } else {
                  this.refreshList();
                }
                return "子账户已成功删除";
              })
              .catch(({ code, message }) => {
                throw `子账户删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/application/${
            this.$route.params.id
          }/sub_account/list?offset=${(curPage - 1) * limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.totalPage = res.total;
          this.sources = res.sub_accounts;
          this.curProviderType = res.type;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    uploadFile(value) {
      let formData = new FormData();
      formData.append("file", value);
      this.loading = true;
      this.$http
        .post(
          `api/application/${this.$route.params.id}/sub_account/import`,
          formData
        )
        .delegateTo(api_request)
        .then(() => {
          this.refreshList();
          this.loading = false;
          return "导入子账户成功";
        })
        .catch(({ code, message }) => {
          this.loading = false;
          throw `导入子账户失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    exportUser() {
      window.location.href = `/api/application/${this.$route.params.id}/sub_account/export`;
    },
    getCurIdData(id) {
      this.$http
        .get(`api/application/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.curApplicationName = data.name;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox,
    CreateSubApplication,
    EditSubApplication
  }
};
</script>

<style lang="less" scoped>
.SourceList {
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    margin-top: 20px;
    width: 96%;
  }
  .v-alert {
    /deep/ .v-alert__content {
      color: #213142;
    }
    /deep/ .v-alert__icon {
      font-size: 2rem !important;
      height: 3rem;
    }
  }
  /deep/ .v-input__prepend-outer {
    margin-right: 0 !important;
  }
  /deep/.v-progress-circular {
    margin: 1rem;
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
