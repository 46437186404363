<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            $t("application.Create {type} sub adapter config", {
              type: curApplicationName
            })
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        子账户列表项
      </v-alert>

      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 主账户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="loginName"
            :error-messages="
              accountNameError === 'accountNameError'
                ? '请输入正确的账号名'
                : ''
            "
            :disabled="subType !== 'create'"
            @input="searchLoginName(loginName)"
            :append-icon="isExist ? 'mdi-check' : ''"
            required
            :rules="rules.isExistName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 子账户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            :auto-grow="true"
            rows="1"
            v-model="claims.username"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="curProviderType === 'FORM_FILL'">
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 密码</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="claims.password"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            :auto-grow="true"
            rows="1"
            persistent-hint
            required
            :rules="rules.notNullRules"
            type="text"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader> 描述</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="description"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            :auto-grow="true"
            rows="1"
            persistent-hint
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!is_readonlySub">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn elevation="4" medium color="primary" @click="create"
            >创建</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { create_subApplication } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SubApplication",
  props: [
    "subId",
    "is_readonlySub",
    "subType",
    "curSubData",
    "curProviderType",
    "curApplicationName"
  ],
  data() {
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      loginName: "",
      claims: {},
      description: "",
      account_id: "",
      curSubId: "",
      accountNameError: null,
      isExist: false,
      allLoginNames: [],
      showPassword: false,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isExistName: [v => !!v || "请输入正确的账号名"]
      }
    };
  },
  created() {
    if (this.subType !== "create") {
      this.fetchData();
    }
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        if (this.account_id) {
          let payload = {
            account_id: this.account_id,
            claims: this.claims,
            description: this.description
          };

          create_subApplication(this.$route.params.id, payload)
            .then(() => {
              this.$store.commit("show_sheet", { show: false });
              bus.$emit("refreshSub");
              return "子账户创建成功";
            })
            .catch(({ code, message }) => {
              throw `子账户创建失败：${this.$t(
                "api." + code
              )}, 额外信息: ${"api." + JSON.stringify(message)}`;
            })
            .delegateTo(this.$snackbar.delegate);
        } else {
          this.accountNameError = "accountNameError";
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    searchLoginName(searchName) {
      if (searchName) {
        // 调取左侧账户名称搜索api
        return this.$http
          .get(
            `api/accounts/search?filter_by=login_name&value=${searchName}&limit=10`
          )
          .delegateTo(api_request)
          .then(res => {
            let searchData = res.accounts;
            if (
              searchData &&
              searchData.find(item => item.login_name === this.loginName)
            ) {
              let accountId = searchData.find(
                item => item.login_name === this.loginName
              ).id;
              this.account_id = accountId;
              this.isExist = accountId ? true : false;
              this.accountNameError = null;
            } else {
              this.isExist = false;
              this.account_id = null;
              this.accountNameError = "accountNameError";
            }
          })
          .catch(({ code, message }) => {
            throw `获取账号名失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      } else {
        this.isExist = false;
        this.account_id = null;
      }
    },
    allAccounts() {
      // 调取所有账户列表数据
      return this.$http
        .get(`api/account/list?offset=0&limit=10`)
        .delegateTo(api_request)
        .then(res => {
          this.allLoginNames = res.accounts;
          return res.accounts;
        })
        .catch(({ code, message }) => {
          throw `获取所有账号名失败：${this.$t(
            `${"api." + code}`
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.subType === "create") {
          this.reset();
        }
      }
    }
  }
};
</script>
