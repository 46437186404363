<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonlySub
              ? `${$t("application.See {type} sub adapter config", {
                  type: curApplicationName
                })}：${curSubData.account.login_name ||
                  curSubData.account.fullname}`
              : `${$t("application.Edit {type} sub adapter config", {
                  type: curApplicationName
                })}：${curSubData.account.login_name ||
                  curSubData.account.fullname}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        子账户列表项
      </v-alert>

      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 主账户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="loginName"
            :disabled="subType !== 'create'"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 子账户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            :auto-grow="true"
            rows="1"
            v-model="username"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="curProviderType === 'FORM_FILL'">
        <v-col cols="3" class="mt-2">
          <v-subheader><span class="red--text">*</span> 密码</v-subheader>
        </v-col>
        <v-col cols="9" v-if="subType !== 'create'">
          <v-text-field
            v-model="password"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            :auto-grow="true"
            rows="1"
            persistent-hint
            required
            :rules="rules.notNullRules"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="controlPwd"
            :type="showPassword ? 'text' : ''"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader> 描述</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="description"
            :readonly="is_readonlySub"
            :disabled="is_readonlySub"
            :auto-grow="true"
            rows="1"
            persistent-hint
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!is_readonlySub">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn elevation="4" medium color="primary" @click="save">提交</v-btn>
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import { modify_subApplication } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "SubApplication",
  props: [
    "subId",
    "is_readonlySub",
    "subType",
    "curSubData",
    "curProviderType",
    "curApplicationName"
  ],
  data() {
    let isRightName = value => {
      return value;
    };
    let isRight = value => {
      const reg = /^[0-9a-zA-Z]+$/;
      return (
        value && reg.test(value) && value.length >= 3 && value.length <= 20
      );
    };
    return {
      valid: true,
      loginName: "",
      username: null,
      password: null,
      description: "",
      account_id: "",
      curSubId: "",
      isExist: false,
      allLoginNames: [],
      showPassword: false,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isRight: [v => isRight(v) || "3-20个字符，只能包含数字和字母"],
        isExistName: [v => isRightName(v) || "请输入正确的账号名"]
      }
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          claims: {
            username: this.username,
            password: this.password
          },
          description: this.description
        };
        const promise = modify_subApplication(
          this.$route.params.id,
          this.curSubId,
          payload
        )
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refreshSub");
            return "子账户更新成功";
          })
          .catch(({ code, message }) => {
            throw `子账户修改失败：${this.$t(
              "api." + code
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    async fetchData() {
      this.showPassword = false;
      let res = await this.allAccounts();
      let { id, claims, description, account } = this.curSubData;
      let loginName = res.find(item => item.id === account.id).login_name;
      if (loginName) {
        this.loginName = loginName;
      } else {
        this.loginName = null;
      }
      this.curSubId = id;
      this.username = claims.username;
      this.password = "........";
      this.description = description;
    },
    allAccounts() {
      // 调取所有账户列表数据
      return this.$http
        .get(`api/account/list?offset=0&limit=1000000000`)
        .delegateTo(api_request)
        .then(res => {
          this.allLoginNames = res.accounts;
          return res.accounts;
        })
        .catch(({ code, message }) => {
          throw `获取所有账号名失败：${this.$t(
            `${"api." + code}`
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    controlPwd() {
      if (!this.showPassword) {
        return this.$http
          .get(
            `api/application/${this.$route.params.id}/sub_account/${this.curSubId}/password`
          )
          .delegateTo(api_request)
          .then(({ password }) => {
            this.password = password;
            this.showPassword = !this.showPassword;
          })
          .catch(({ code, message }) => {
            throw `获取密码失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      } else {
        this.password = "........";
        this.showPassword = !this.showPassword;
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.subType === "create") {
          this.reset();
        } else if (this.subType === "edit" && value) {
          this.fetchData();
        }
      }
    }
  }
};
</script>
